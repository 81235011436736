import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import Header from 'components/Header/Header';
import About from 'components/About/About';
import Offer from 'components/Offer/Offer';
import Certificates from 'components/Certificates/Certificates';
import WhySAP from 'components/WhySAP/WhySAP';
import Competences from 'components/Competences/Competences';
import Contact from 'components/Contact/Contact';
import Career from 'components/Career/Career';
import OurTeam from 'components/OurTeam/OurTeam';

import { teamMembers } from 'assets/data/team';
import slugify from 'slugify';

const IndexPage = ({ data }) => {
  const heroImg = data.heroImg.childImageSharp.fluid;
  const card1Img = data.card1Img.childImageSharp.fluid;
  const card2Img = data.card2Img.childImageSharp.fluid;
  const card3Img = data.card3Img.childImageSharp.fluid;
  const certificateImg = data.certificateImg.childImageSharp.fluid;
  const certificatePic = data.certificatePicImg.childImageSharp.fluid;
  const certificatePrince = data.certificatePrinceImg.childImageSharp.fluid;
  const jobOffers = data.allWpPost.edges;

  const slides = [
    `System <b>SAP</b><br/>strategią na rozwój`,
    `<b>Aseti</b> - profesjonalni w biznesie, skuteczni w działaniu`,
    `<b>Aseti</b> - siła doświadczenia<br/> szerokie kompetencje`,
    `<b>Aseti</b> - wyższy<br/>poziom biznesu`,
  ];

  const cards = [
    {
      image: card1Img,
      imageAlt: 'card',
      description:
        'Wieloletnie doświadczenie we wdrażaniu rozwiązań SAP ERP dla różnych branż',
    },
    {
      image: card3Img,
      imageAlt: 'card',
      description: 'Umiejętność pracy zespołowej',
    },
    {
      image: card2Img,
      imageAlt: 'card',
      description: 'Umiejętności zrozumiałego komunikowania się z biznesem',
    },
  ];

  const offerCards = [
    {
      icon: data.userIcon.publicURL,
      iconAlt: 'user icon',
      description: 'Implementacje i Roullout SAP',
    },
    {
      icon: data.likeIcon.publicURL,
      iconAlt: 'like icon',
      description: 'Integracja przy zastosowaniu rozwiązania SAP PI',
    },
    {
      icon: data.groupIcon.publicURL,
      iconAlt: 'group icon',
      description: 'Migracja na nowe platformy sprzętowe i bazodanowe',
    },
    {
      icon: data.internetIcon.publicURL,
      iconAlt: 'internet icon',
      description:
        'Integracja SAP z innymi systemami<span>(webservice, idoc (EDI))</span>',
    },
    {
      icon: data.workIcon.publicURL,
      iconAlt: 'work icon',
      description: 'Audyt wdrożeń SAP',
    },
    {
      icon: data.clockIcon.publicURL,
      iconAlt: 'clock icon',
      description: 'Serwis aplikacyjny SAP',
    },
    {
      icon: data.laptopIcon.publicURL,
      iconAlt: 'laptop icon',
      description: 'Upgrade SAP',
    },
    {
      icon: data.serverIcon.publicURL,
      iconAlt: 'server icon',
      description: 'Administracja - SAP Basis',
    },
  ];

  const competencesCards = [
    {
      icon: data.competence3Icon.publicURL,
      iconAlt: 'icon',
      description: 'Rachunkowość Finansowa <span>(SAP FI)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Księgowość Majątku Trwałego <span>(SAP FI -AA)</span>',
    },
    {
      icon: data.competence1Icon.publicURL,
      iconAlt: 'icon',
      description: 'Zarządzanie Środkami Pieniężnymi <span>(SAP FI-TR)</span>',
    },
    {
      icon: data.competence5Icon.publicURL,
      iconAlt: 'icon',
      description: 'Kontroling <span>(SAP CO)</span>',
    },
    {
      icon: data.competence4Icon.publicURL,
      iconAlt: 'icon',
      description: 'Zarządzanie Inwestycjami <span>(SAP IM)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Zarządzanie Projektami <span>(SAP PS)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Gospodarka Materiałowa, Zaopatrzenie <span>(SAP MM)</span>',
    },
    {
      icon: data.competence1Icon.publicURL,
      iconAlt: 'icon',
      description:
        'Gospodarka Magazynowa, Zarządzanie Magazynem <span>(SAP MM, SAP WMS)</span>',
    },
    {
      icon: data.competence5Icon.publicURL,
      iconAlt: 'icon',
      description: 'Sprzedaż i Dystrybucja <span>(SAP SD)</span>',
    },
    {
      icon: data.competence4Icon.publicURL,
      iconAlt: 'icon',
      description: 'Planowanie Produkcji <span>(SAP PP)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Zarządzanie jakością <span>(SAP QM)</span>',
    },
    {
      icon: data.competence3Icon.publicURL,
      iconAlt: 'icon',
      description: 'Utrzymanie ruchu <span>(SAP PM)</span>',
    },
  ];

  const certSections = [
    {
      section: 'Infrastructure & Administration',
      certificates: [
        {
          name: `SAP Certified Technology Consultant SAP NetWeaver '04 - SAP Web AS for Oracle`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Technology Associate - OS/DB Migration for SAP NetWeaver 7.52`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Application Associate - SAP Mobile Platform Native and Hybrid Application Administration (SMP 2.3)`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Support Associate - Incident Management with SAP BusinessObjects `,
          image: certificateImg,
        },
        {
          name: `SAP Certified Support Associate - SAP HANA`,
          image: certificateImg,
        },
        {
          name: `Support Consultant for Incident Management with SAP Business All-in-One`,
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Application - Finance & Controlling',
      certificates: [
        {
          name: 'Certyfikat Management Accounting (CO) SAP ERP',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Application Associate - Financial Accounting with SAP ERP 6.0 EhP7',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Application Associate - Financial Accounting with SAP ERP 6.0 EHP4',
          image: certificateImg,
        },
        { name: 'MySAP Financials – ERP', image: certificatePic },
        { name: 'Szkoła Controllingu V-IX 2000', image: certificatePic },
      ],
    },
    {
      section: 'Application - Logistics',
      certificates: [
        {
          name: 'SAP Certified Application Associate - Order Fulfillment with SAP ERP 6.0 EHP5',
          image: certificateImg,
        },
        {
          name: 'SAP Solution Consultant SCM - Order Fulfillment with mySAP ERP',
          image: certificateImg,
        },
        {
          name: 'Application Consultant ‘Sales and Distribution’ with mySAP.com. Certification2000',
          image: certificateImg,
        },
        {
          name: 'Solution Consultant SCM - Procurement with my SAP ERP',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Application - Human Capital Management',
      certificates: [
        {
          name: 'SAP Certified Application Associate - Human Capital Management with SAP ERP 6.0 EHP5',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Integration & Development',
      certificates: [
        {
          name: 'SAP Certified Development Associate - Process Integration with SAP NetWeaver (PI 7.3)',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Development Associate - ABAP with SAP NetWeaver 7.50',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Development Consultant SAP NetWeaver 2004 - Application Development Focus ABAP',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Project Management',
      certificates: [
        {
          name: 'Prince2 Foundation (APM Group) 2012',
          image: certificatePrince,
        },
        {
          name: 'Prince2 Practitioner (APM Group) 2011',
          image: certificatePrince,
        },
      ],
    },
  ];

  const TEAM_MEMBERS = useMemo(
    () =>
      teamMembers.map(member => ({
        name: member.name,
        surname: member.surname,
        slug: slugify(`${member.name}-${member.surname}`, {
          lower: true,
        }),
        img: data[`teamMember${member.id}`].childImageSharp.fluid,
        linkedin: member.linkedin,
        role: member.role,
      })),
    []
  );

  return (
    <>
      <SEO title="Strona główna" />
      <div id="home" />
      <Header image={heroImg} imageAlt="banner image" slides={slides} />
      <main>
        <About
          heading="O <b>Nas</b>"
          cards={cards}
          description1="Nasz zespół to doświadczeni konsultanci, programiści oraz kierownicy projektów. Doświadczenie, profesjonalizm, skuteczność w działaniu – tak w skrócie można opisać nasze kompetencje. Wieloletnia praca przy wdrażaniu systemu SAP pozwoliła nam zbudować bogate know-how, dzięki czemu możemy oferować kompleksowe rozwiązania skrojone na miarę potrzeb naszych Klientów z każdej branży"
          description2="Skuteczna implementacja systemów ERP wymaga od nas nie tylko obszernej wiedzy z zakresu samego rozwiązania informatycznego oraz możliwości jego konfiguracji ale przede wszystkim dogłębnej znajomości procesów związanych z wdrażanymi funkcjonalnościami – jak rachunkowość, finanse, kontroling, logistyka, czy produkcja.<br/><br/>Oprócz wiedzy opartej na wieloletnim doświadczeniu z zakresu rozwiązań SAP ERP, SAP S/4 HANA wszyscy nasi konsultanci legitymują się certyfikatami firmy SAP w obsługiwanych przez siebie obszarach. Dysponujemy również grupą bardzo doświadczonych programistów ABAP."
        />

        <Competences
          heading="Nasze <b>kompetencje</b>"
          cards={competencesCards}
        />
        <OurTeam heading="Poznaj <b>nasz zespół</b>" members={TEAM_MEMBERS} />
        <Certificates heading="Certyfikaty" sections={certSections} />
        <WhySAP
          heading="Dlaczego <b>SAP?</b>"
          description="Aby skutecznie realizować swoją strategię każde przedsiębiorstwo musi mieć narzędzia wspomagające osiągnięcie zamierzonych celów. Do takich narzędzi należą nowoczesne systemy informatyczne firmy SAP A.G. To właśnie one otwierają nowe możliwości podejmowania trafnych decyzji biznesowych, zwiększają efektywność i wydajność działań oraz pozwalają na znaczne zmniejszenie kosztów działalności firm. Łatwa i intuicyjna obsługa, usprawnienie wymiany informacji, automatyzacja powtarzalnych czynności, są z kolei cechami, które bardzo cenią sobie pracownicy korzystający z systemu. Firma SAP uważana jest za lidera w dziedzinie oprogramowania do zarządzania przedsiębiorstwami, zaś system SAP S/4 HANA za niekwestionowany numer jeden na świecie. Swoją popularność zawdzięcza ogromnym możliwościom funkcjonalnym, elastyczności konfiguracji oraz nowoczesnej technologii. Ponad to użytkownicy systemów SAP ERP oraz SAP S/4 HANA podkreślają jego bezpieczeństwo, duże możliwości integracji i współdziałania z innymi systemami oraz elastyczność i skalowalność."
        />
        <Offer
          heading="Oferta - czyli co możemy dla <b>Ciebie</b> zrobić"
          cards={offerCards}
        />
        {jobOffers.length && (
          <Career heading="Oferty pracy" cards={jobOffers} />
        )}
        <Contact
          header={{
            title: 'Misja',
            description:
              'Naszą misją jest dostarczanie usług najwyższej jakości w zakresie wdrożeń, rozwoju i innowacyjności oraz utrzymania systemu SAP ERP.',
          }}
          contactHeading="Skontaktuj się <b>z Nami</b>"
          contactData="<b>Aseti Sp. z o.o.</b><br/><br/>ul. Marynarska 21<br/>02-674 Warszawa<br/><br/>NIP 5252821667<br/>REGON 385964076"
          contactEmail="biuro@aseti.pl"
        />
      </main>
    </>
  );
};

export const query = graphql`
  {
    heroImg: file(name: { eq: "hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card1Img: file(name: { eq: "card3" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card2Img: file(name: { eq: "card1" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card3Img: file(name: { eq: "card2" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificateImg: file(name: { eq: "certificate" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificatePicImg: file(name: { eq: "certificate-pic" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificatePrinceImg: file(name: { eq: "certificate-prince" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember1: file(name: { eq: "team-member-1" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember2: file(name: { eq: "team-member-2" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember3: file(name: { eq: "team-member-3" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember4: file(name: { eq: "team-member-4" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember5: file(name: { eq: "team-member-5" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember6: file(name: { eq: "team-member-6" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember7: file(name: { eq: "team-member-7" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember8: file(name: { eq: "team-member-8" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember9: file(name: { eq: "team-member-9" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember10: file(name: { eq: "team-member-10" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    userIcon: file(name: { eq: "user" }) {
      publicURL
    }
    likeIcon: file(name: { eq: "like" }) {
      publicURL
    }
    groupIcon: file(name: { eq: "group" }) {
      publicURL
    }
    internetIcon: file(name: { eq: "internet" }) {
      publicURL
    }
    workIcon: file(name: { eq: "work-shedule" }) {
      publicURL
    }
    clockIcon: file(name: { eq: "wall-clock" }) {
      publicURL
    }
    laptopIcon: file(name: { eq: "laptop" }) {
      publicURL
    }
    serverIcon: file(name: { eq: "server" }) {
      publicURL
    }
    competence1Icon: file(name: { eq: "competence1" }) {
      publicURL
    }
    competence2Icon: file(name: { eq: "competence2" }) {
      publicURL
    }
    competence3Icon: file(name: { eq: "competence3" }) {
      publicURL
    }
    competence4Icon: file(name: { eq: "competence4" }) {
      publicURL
    }
    competence5Icon: file(name: { eq: "competence5" }) {
      publicURL
    }
    allWpPost {
      edges {
        node {
          jobOffer {
            jobCity
            jobOfferEnding
            jobOfferIntro
            jobOfferPosition
            jobPosition
            jobOfferNicetosee {
              jobOfferNicetoseeSingle
            }
            jobOfferOuroffer {
              jobOfferOurofferSingle
            }
            jobOfferRequirements {
              jobOfferRequirementsSingle
            }
            jobOfferResponsibilities {
              jobOfferResponsibilitesSingle
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default IndexPage;
